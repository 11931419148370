import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import * as api from "../utils/api";
import { transformArrayToHashId } from "../utils/utils";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { Button } from "react-bootstrap-buttons";
import moment from "moment-timezone";
import { connect } from "react-redux";
import * as actionCreators from "../modules/actions";
import ElementDetails from "./socialmediaposts/ElementDetails";
import CustomLoader from "../components/CustomLoader";

const renderListItem = (item, active, setActive, name) => {
  return (
    <li
      key={item.id}
      className={"d-flex justify-content-start align-items-center border-bottom py-3 pl-2 " + (item.id == active ? "bg-white" : "")}
      onClick={() => setActive(item.id)}
    >
      <div className="align-content-center" style={{wordBreak : 'break-word',marginRight : 15}}>
        <p className="m-auto pl-2 fs-h5 settings--p__navy">
          {item.id == "Draft" ? "Draft" : name}
        </p>
      </div>
    </li >
  );
}

const SocialMediaPosts = props => {
  const [activePost, setActivePost] = useState(null);
  const [activePostingRule, setActivePostingRule] = useState(null);
  const [posts, setPosts] = useState({});
  const [postingRules, setPostingRules] = useState({});
  const [tab, setTab] = useState(0);
  const [meetups, setMeetups] = useState([]);
  const [posterOptions, setPosterOptions] = useState({})
  const [defaultPosterOption, setDefaultPosterOption] = useState(null)
  const [fbDefaults, setFbDefaults] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.client_loading) { return }
    loadPosts();
    api.endpoints.getSocialMediaPostingRules(
      (res) => {
        setPostingRules(transformArrayToHashId(res));
      }
    );
    api.endpoints.getMeetups(
      {
        start_date: moment().tz(props.timezone).format(),
        published: true,
        postable_text: true
      },
      (data) => {
        setMeetups(data.meetups);
      }
    );
    api.endpoints.getPosterOptions(
      (res) => setPosterOptions(transformArrayToHashId(res.poster_options))
    );
    api.endpoints.getPosterSettings(
      (res) => setDefaultPosterOption(res.social_media)
    );
    api.endpoints.getFBStatus(
      (res) => {
        setFbDefaults(res);
        setLoading(false)
      },
      (err) => { },
      { no_posting_areas: true }
    )
  }, [props.client_loading, props.timezone]);

  const loadPosts = () => {
    api.endpoints.getSocialMediaPosts(
      (res) => {
        setPosts(transformArrayToHashId(res));
      }
    );
  }

  const makeNewPost = () => {
    setPosts({
      ...posts,
      "Draft": {
        post_date: moment().tz(props.timezone).add(1, 'hour').startOf('hour'),
        id: "Draft",
        header: fbDefaults.mp_default_header ? fbDefaults.mp_default_header_text : "",

        text_post: fbDefaults.mp_text_post,
        image_post: fbDefaults.mp_image_post,
        video_post: fbDefaults.mp_video_post,

        attachment: fbDefaults.mp_attachment,
        video_attachment_facebook: fbDefaults.mp_video_attachment_facebook,

        instagram: fbDefaults.instagram ? true:false,
        instagram_image_post: fbDefaults.mp_instagram_image_post,
        instagram_video_post: fbDefaults.mp_instagram_video_post,

        image_story_instagram: fbDefaults.mp_image_story_instagram,
        video_story_instagram: fbDefaults.mp_video_story_instagram,


        instagram_attachment: fbDefaults.mp_instagram_attachment,
        video_attachment_instagram: fbDefaults.mp_video_attachment_instagram,
        attachment_story_instagram: fbDefaults.mp_attachment_story_instagram,
        video_attachment_story_instagram: fbDefaults.mp_video_attachment_story_instagram,

        image: null,
        additional_game_days: 0,
        poster_option_id: defaultPosterOption,
        video: null,
        is_short_video: fbDefaults.mp_is_short_video,
        instagram_caption: fbDefaults.mp_instagram_caption,

      }
    })
    setActivePost("Draft")
  }

  const makeNewPostingRule = () => {
    setPostingRules({
      ...postingRules,
      "Draft": {
        id: "Draft",
        name: "",
        autogenerated_header: !fbDefaults.mp_default_header,
        ai_header: !fbDefaults.mp_default_header && fbDefaults.mp_ai_header ? fbDefaults.mp_ai_header : false ,
        post_time: moment().tz(props.timezone).add(1, 'hour').startOf('hour'),
        header: fbDefaults.mp_default_header ? fbDefaults.mp_default_header_text : "",
        text_post: fbDefaults.mp_text_post,
        instagram: fbDefaults.instagram ? true:false,
        instagram_caption: fbDefaults.mp_instagram_caption,
        image: null,
        video: null,
        is_short_video: mp_is_short_video,


        image_post: fbDefaults.mp_image_post,
        video_post: fbDefaults.mp_video_post,

        attachment: fbDefaults.mp_attachment,
        video_attachment_facebook: fbDefaults.mp_video_attachment_facebook,

        instagram_image_post: fbDefaults.mp_instagram_image_post,
        instagram_video_post: fbDefaults.mp_instagram_video_post,

        image_story_instagram: fbDefaults.mp_image_story_instagram,
        video_story_instagram: fbDefaults.mp_video_story_instagram,

        instagram_attachment: fbDefaults.mp_instagram_attachment,
        video_attachment_instagram: fbDefaults.mp_video_attachment_instagram,
        attachment_story_instagram: fbDefaults.mp_attachment_story_instagram,
        video_attachment_story_instagram: fbDefaults.mp_video_attachment_story_instagram,

        additional_game_days: 0,
        days: [],
        poster_option_id: defaultPosterOption
      }
    })
    setActivePostingRule("Draft")
  }

  const handleTabChange = (tabIndex) => {
    setTab(tabIndex);
    setActivePostingRule(null);
    setActivePost(null);
  }

  return (
    props.client_loading === false && (
    <div className="container-fluid">
      <div className="col flex-nowrap bg-light">
        <Header container="scheduler" />
          <div className="content-height border d-flex w-100">
            <div className="d-flex flex-column bg-secondary w-25 h-100">
              <CustomLoader loading={loading}>
                <Tabs
                  className="w-100 tabs-container h-100"
                  headerClass="tabs-header"
                  activeHeaderClass="tabs-header--active"
                  onSelect={(k) => handleTabChange(k) }
                  selected={tab}
                >
                  <Tab label="Schedule" className="h-100 smp-tab">
                    <div className="d-flex flex-column h-100">
                      <div className="text-center">
                        <Button
                          className="my-2 button--success fs-btn2"
                          onClick={makeNewPost}
                          style={{ width : 150 }}
                        >Schedule Post</Button>
                      </div>
                      <ul className="p-0 m-0 w-100 active-list overflow-auto">
                        {Object.keys(posts).sort(function (a, b) {
                          return (new Date(posts[a].post_date) - new Date(posts[b].post_date));
                        }).map((key) => {
                          const date = moment(posts[key].post_date).tz(props.timezone).format("YYYY-MM-DD");
                          const dateObj = (typeof posts[key].post_date == "string") ? new Date(posts[key].post_date) : posts[key].post_date;
                          return renderListItem(posts[key], activePost, setActivePost,
                            (date + " ( " + moment(dateObj).tz(props.timezone).format("dddd") + " ) - ") + (posts[key].posting_rule_name || ""));
                        })}
                      </ul>
                    </div>
                  </Tab>
                  <Tab label="Posting Rules" className="h-100 smp-tab">
                    <div className="text-center">
                      <Button
                        className="my-2 button--success fs-btn2"
                        onClick={makeNewPostingRule}
                        style={{ width : 150 }}
                      >Create Posting Rule</Button>
                    </div>
                    <ul className="p-0 m-0 w-100  active-list">
                      {Object.keys(postingRules).map((key) => {
                        return renderListItem(postingRules[key], activePostingRule, setActivePostingRule, postingRules[key].name)
                      })}
                    </ul>
                  </Tab>
                </Tabs>
              </CustomLoader>
            </div>
            {tab === 0 && (activePost != null ? (
              <ElementDetails
                elements={posts}
                activeElement={activePost}
                setElements={setPosts}
                setActiveElement={setActivePost}
                createF={api.endpoints.createSocialMediaPost}
                updateF={api.endpoints.updateSocialMediaPost}
                deleteF={api.endpoints.deleteSocialMediaPost}
                tab={"posts"}
                meetups={meetups}
                posterOptions={posterOptions}
                defaultPosterOption={defaultPosterOption}
                fbDefaults={fbDefaults}
                timezone={props.timezone}
                time_format={props.time_format}
              />
            ) : (
                <div className="w-100"></div>
              ))
            }
            {tab === 1 && (activePostingRule != null ? (
              <ElementDetails
                elements={postingRules}
                activeElement={activePostingRule}
                setElements={setPostingRules}
                setActiveElement={setActivePostingRule}
                tab={"postingRules"}
                createF={api.endpoints.createSocialMediaPostingRule}
                updateF={api.endpoints.updateSocialMediaPostingRule}
                deleteF={api.endpoints.deleteSocialMediaPostingRule}
                posterOptions={posterOptions}
                defaultPosterOption={defaultPosterOption}
                fbDefaults={fbDefaults}
                loadPosts={loadPosts}
                timezone={props.timezone}
                time_format={props.time_format}
              />
            ) : (
                <div className="w-100"></div>
              ))
            }
          </div>
      </div>
    </div>
    )
  );
};

const mapDispatchToProps = dispatch => {
  return {
    showNotice: (message, success, options) =>
      dispatch(actionCreators.showNotice(message, success, options)),
    hideNotice: params =>
      dispatch(actionCreators.hideNotice(params))
  };
};

const mapStateToProps = state => {
  return {
    timezone: state.timezone,
    time_format: state.time_format,
    client_loading: state.client_loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialMediaPosts);
