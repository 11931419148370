import React, { Component } from "react";
import { Button } from "react-bootstrap-buttons";
import { registerLocale } from "react-datepicker";
import { Formik, Form } from "formik";
import enGB from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import * as actionCreators from "../modules/actions";
import { connect } from "react-redux";
import CustomSwitch from "./CustomSwitch";
import CustomSwitchShareEvents from "./CustomSwitchShareEvents";
import FileUploadShareEvents from "./FileUploadShareEvents";
import CustomButtonShareEvents from "./CustomButtonShareEvents";
import CustomPostSelector from "./CustomPostSelector";
import { UploadableImageFrame } from "./UploadableImageFrame";
import UploadableVideoFrame from "./UpdatableVideoFrame";

/**Register the locale to display calendar in UK format, week starting with Monday */
registerLocale("en-GB", enGB);
/**Store the folder path for webpack created images folder */
const images = require.context("../assests/images/img", true);
const width_style = { width: "350px" };
const height_style = [
	{ height: "55px" },
	{ height: "25px" },
	{ height: "30px" },
];

const postingOptionsErrorMessages = {
	attVideoErr:
		"Facebook's guidelines do not allow videos to be posted together with another videos or images.",
	imageErr:
		"Facebook's guidelines do not allow images to be posted together with videos.",
	videoErr:
		"Facebook's guidelines do not allow videos to be posted together with images.",
};

const emptyPost = {
	ai_header: false,
	custom_header: false,
	custom_header_text: "",
	custom_footer: false,
	custom_footer_text: "",
	instagram: false,
	image_post: false,
	text_post: false,
	video_post: false,
	is_short_video: false,
	instagram_image_post: false,
	instagram_video_post: false,
	instagram_caption: false,
	instagram_attachment: false,
	video_story_instagram: false,
	image_story_instagram: false,
	attachment_story_instagram: false,
	image: null,
	video: null,
	fbVideoErr: null,
	fbImageErr: null,
	igVideoErr: null,
	igImageErr: null,
};

/**Component displayed as a modal popup with input for title, description and event date/time for custom event creation
 * @param cancelModal : Cancel Modal when clicked outside
 * @param evtForm : Form element with event title and description input fields
 * @param moment = prop for date picker element for default date
 * @param handleDateSelect = prop for date picker for handling date selection
 * @param onChange = Handler for event title and desc onChange
 * @param createEvent = Handler for create event button click
 *
 * Imported compoenent DatePicker for the date selection feature
 * CustomInputDate sent as prop to DatePicker to render the customizated
 * input text field with calender icon at right
 */
class PostEditorPopup extends Component {
	constructor(props) {
		super(props);
		this.changeHandler = this.changeHandler.bind(this);
		this.state = {
			post: emptyPost,
			events: props.selEventIdList,
			defaultHeaderText: "",
		};
		if (props.postEditorDefaults) {
			this.state = {
				post: {
					...this.state.post,
					ai_header: Boolean(props.postEditorDefaults.ai_header),
					custom_header: Boolean(props.postEditorDefaults.default_header),
					custom_header_text: Boolean(props.postEditorDefaults.default_header)
						? props.postEditorDefaults.default_header_text
						: "",
					custom_footer: Boolean(props.postEditorDefaults.default_footer),
					custom_footer_text: props.postEditorDefaults.default_footer_text,
					instagram: props.postEditorDefaults.instagram,
					image_post: Boolean(props.postEditorDefaults.image_post),
					text_post: Boolean(props.postEditorDefaults.text_post),
					video_post: Boolean(props.postEditorDefaults.video_post),
					is_short_video: Boolean(props.postEditorDefaults.is_short_video),
					attachment: Boolean(props.postEditorDefaults.attachment),
					instagram_image_post: Boolean(
						props.postEditorDefaults.instagram_image_post
					),
					instagram_video_post: Boolean(
						props.postEditorDefaults.instagram_video_post
					),
					video_story_instagram: Boolean(
						props.postEditorDefaults.video_story_instagram
					),
					image_story_instagram: Boolean(
						props.postEditorDefaults.image_story_instagram
					),
					instagram_caption: props.postEditorDefaults.instagram_caption,
					instagram_attachment: Boolean(
						props.postEditorDefaults.instagram_attachment
					),
					attachment_story_instagram: Boolean(
						props.postEditorDefaults.attachment_story_instagram
					),
					poster_setting: props.posterSetting.social_media,
					poster_options: props.posterOptions,
					fbVideoErr: null,
					fbImageErr: null,
					igVideoErr: null,
					igImageErr: null,
				},
				defaultHeaderText: props.postEditorDefaults.default_header_text || "",
			};
		}
	}

	componentDidMount() {
		const { post } = this.state;
		const {
			instagram_image_post,
			instagram_video_post,
			video_post,
			image_post,
		} = post;
		const postingOptionErrorChanges = {};
		if (!video_post && image_post) {
			postingOptionErrorChanges.fbVideoErr =
				postingOptionsErrorMessages.videoErr;
		}

		if (video_post && !image_post) {
			postingOptionErrorChanges.fbImageErr =
				postingOptionsErrorMessages.imageErr;
		}

		if (!instagram_video_post && instagram_image_post) {
			postingOptionErrorChanges.igVideoErr =
				postingOptionsErrorMessages.videoErr;
		}
		if (instagram_video_post && !instagram_image_post) {
			postingOptionErrorChanges.igImageErr =
				postingOptionsErrorMessages.imageErr;
		}

		if (Object.keys(postingOptionErrorChanges).length > 0) {
			this.setState((currentState) => ({
				post: {
					...currentState.post,
					...postingOptionErrorChanges,
				},
			}));
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.isUpdating) {
			return;
		}

		this.isUpdating = true;

		const { post } = this.state;
		const {
			custom_header_text,
			ai_header,
			custom_header,
			video,
			instagram_image_post,
			image_story_instagram,
			instagram_video_post,
			video_story_instagram,
			video_post,
			image_post,
			image,
		} = post;
		const { header_message } = this.props;

		const stateChanges = { ...post };

		const resetErrors = (errors) => {
			for (const error of errors) {
				stateChanges[error] = null;
			}
		};

		const resetPostingOptions = () => {
			stateChanges.instagram_image_post = false;
			stateChanges.instagram_video_post = false;
			stateChanges.video_post = false;
			stateChanges.image_post = false;
			stateChanges.image = null;
			resetErrors(["fbVideoErr", "fbImageErr", "igVideoErr", "igImageErr"]);
		};

		const imageResetOptions = () => {
			stateChanges.instagram_video_post = false;
			stateChanges.video_post = false;
			resetErrors(["fbVideoErr", "fbImageErr", "igVideoErr", "igImageErr"]);
		};

		const shouldUpdateState = (key, newValue, oldValue) => {
			if (newValue !== oldValue) {
				stateChanges[key] = newValue;
			}
		};

		shouldUpdateState(
			"custom_header_text",
			!ai_header && !custom_header ? "" : custom_header_text,
			post.custom_header_text
		);

		if (image_post && prevState.post.video_post) {
			stateChanges.video_post = false;
			stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
			stateChanges.fbImageErr = null;
		}

		if (video_post && prevState.post.image_post) {
			stateChanges.image_post = false;
			stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
			stateChanges.fbVideoErr = null;
		}

		if (instagram_image_post && prevState.post.instagram_video_post) {
			stateChanges.instagram_video_post = false;
			stateChanges.igVideoErr = postingOptionsErrorMessages.videoErr;
			stateChanges.igImageErr = null;
		}

		if (instagram_video_post && prevState.post.instagram_image_post) {
			stateChanges.instagram_image_post = false;
			stateChanges.igImageErr = postingOptionsErrorMessages.imageErr;
			stateChanges.igVideoErr = null;
		}

		if (!video_post && image_post) {
			stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
		}

		if (video_post && !image_post) {
			stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
		}

		if (!instagram_video_post && instagram_image_post) {
			stateChanges.igVideoErr = postingOptionsErrorMessages.videoErr;
		}
		if (instagram_video_post && !instagram_image_post) {
			stateChanges.igImageErr = postingOptionsErrorMessages.imageErr;
		}

		if (!video_post && !image_post) {
			stateChanges.fbVideoErr = null;
			stateChanges.fbImageErr = null;
		}
		if (!instagram_video_post && !instagram_image_post) {
			stateChanges.igVideoErr = null;
			stateChanges.igImageErr = null;
		}

		if (
			header_message !== prevProps.header_message &&
			header_message !== null
		) {
			stateChanges.custom_header_text = header_message;
		}

		if (image) {
			const imageErrorMessage = postingOptionsErrorMessages.imageErr;
			const imageOptionsErrors = ["fbVideoErr", "igVideoErr"];

			imageResetOptions(); // Reset other posting options if needed
			for (const option of imageOptionsErrors) {
				stateChanges[option] = imageErrorMessage;
			}
		}

		if (video) {
			const videoErrorMessage = postingOptionsErrorMessages.attVideoErr;
			const videoOptionsErrors = [
				"fbVideoErr",
				"fbImageErr",
				"igVideoErr",
				"igImageErr",
			];

			resetPostingOptions(); // Reset other posting options if needed
			for (const option of videoOptionsErrors) {
				stateChanges[option] = videoErrorMessage;
			}
		}

		this.setState({ post: stateChanges }, () => {
			this.isUpdating = false;
		});
	}

	tlTipErrorManager = (errMsg) => {
		return (
			<div style={{ position: "relative" }}>
				<div className="tl-tip">
					<img
						className="ml-2 mr-2"
						src={images("./share-events-alert.svg")}
						alt="tip"
					/>
				</div>
				<div className="tl-tip-content">{errMsg}</div>
			</div>
		);
	};

	pluralize = (text) => {
		return (
			text +
			(this.props.selEventIdList && this.props.selEventIdList.length > 1
				? "s"
				: "")
		);
	};

	attachmentPluralize = (text) => {
		return (
			text +
			(this.state.post.image && this.state.post.image.length > 1 ? "s" : "")
		);
	};

	// onError = err => {
	//   this.props.showNotice(err.response.data.message, false);
	// };

	/**Cancels the create custom event modal
	 */
	cancelModal = () => {
		this.props.cancelModal();
	};

	/**OnChange handler for fields in custom event form
	 *@param fieldvalue= value,
	 *@param field= identifier to identify the input element on the form.
	 */
	changeHandler = (field, value) => {
		this.setState((prevState) => ({
			post: {
				...prevState.post,
				[field]: value,
			},
		}));
	};

	handleImageRemove = (index) => {
		this.setState((prevState) => {
			let updatedImages = [...prevState.post.image];
			updatedImages.splice(index, 1);
			if (updatedImages.length == 0) {
				updatedImages = null;
			}
			return {
				post: {
					...prevState.post,
					image: updatedImages,
				},
			};
		});
	};

	handleChangeValue = (field, fieldValue, name) => {
		this.changeHandler(field, fieldValue);
		return name;
	};

	shartEventButton = () => {
		const post = this.state.post;
		const {
			fbVideoErr,
			fbImageErr,
			igVideoErr,
			igImageErr,
			poster_options,
			...postToSubmit
		} = post; // Creates an object without poster_options to send to the backend
		this.props.shareEvents(postToSubmit);
		this.cancelModal();
	};

	validateAttachmentLength = (image) => {
		if (image == null) return false;
		return image.length <= 7;
	};

	validateAttachment = (image) => {
		if (image == null) return false;

		const valid_content_type = [
			"image/jpg",
			"image/jpeg",
			"image/png",
			"image/bmp",
			"video/mp4",
		];
		const valid_extension = [
			".jpg",
			".jpeg",
			".png",
			".bmp",
			".mp4",
			".JPG",
			".JPEG",
			".PNG",
			".BMP",
			".MP4",
		];

		if (Array.isArray(image)) {
			image.forEach((img) => {
				let type = img.type;
				let extension = img.name.match(/\.[0-9a-z]+$/i);
				if (
					!valid_content_type.includes(type) &&
					!valid_extension.includes(extension[0])
				)
					return false;
			});
			return true;
		}

		let type = image.type;
		let extension = image.name.match(/\.[0-9a-z]+$/i);

		if (type == null || extension == null) return false;
		if (
			valid_content_type.includes(type) &&
			valid_extension.includes(extension[0])
		)
			return true;
		else return false;
	};

	validatePost = () => {
		if (
			(this.state.post.image &&
				!this.validateAttachment(this.state.post.image)) ||
			(this.state.post.image &&
				(this.state.post.instagram_attachment || this.state.post.attachment) &&
				!this.validateAttachmentLength(this.state.post.image)) ||
			(this.state.post.video && !this.validateAttachment(this.state.post.video))
		)
			return false;
		return true;
	};

	render() {
		return (
			<div>
				<div className="modal-backdrop bg-secondary opacity6" />
				<div className="modal d-inline-block">
					<div className="modal-dialog-centered modal__custom-event modal__custom-event--dialog-width">
						<div className="modal-content m-auto bg--gray1">
							<div className="modal-header">
								<strong style={{ fontSize: "30px" }}>
									Share on Social Media
								</strong>
							</div>
							<Formik
								enableReinitialize={true}
								initialValues={{}}
								onSubmit={() => {
									this.shartEventButton();
									this.props.deleteHeaderMessage();
								}}
							>
								{({ errors, touched, isSubmitting, setFieldValue }) => (
									<Form>
										<div className="modal-body px-0 ml-0 pt-0 pb-0 bg--gray1 d-flex flex-column">
											<div className="row" style={{ height: "auto" }}>
												<div className="col-md-8 border-right">
													<div className="form-group ">
														<div className="modal__custom-event--fs mb-2 mt-2 d-flex align-items-center">
															<strong>Post Header</strong>
															<div className="ml-auto mr-2">Custom Header</div>
															<CustomSwitch
																checked={this.state.post.custom_header}
																className="event-filter__stations-switch"
																changeOffHandleColor="#000000"
																onChange={(checked) => {
																	this.changeHandler("custom_header", checked);
																	if (this.state.post.ai_header) {
																		this.props.deleteHeaderMessage();
																		this.changeHandler("ai_header", false);
																	}
																	this.changeHandler(
																		"custom_header_text",
																		this.props.postEditorDefaults
																			.default_header_text
																	);
																}}
															/>
															<div
																className="ml-3 mr-1"
																style={{ position: "relative" }}
															>
																<div className="tip">
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="11.335"
																		height="11.31"
																		viewBox="0 0 11.335 11.31"
																	>
																		<g
																			id="Group_274"
																			data-name="Group 274"
																			transform="translate(-478.836 -775)"
																		>
																			<path
																				id="sparkle"
																				d="M11.34,6.8,10.047,6.43A3.535,3.535,0,0,1,7.6,3.987L7.234,2.694a.247.247,0,0,0-.456,0L6.408,3.987A3.535,3.535,0,0,1,3.965,6.43L2.672,6.8a.237.237,0,0,0,0,.456l1.293.369a3.535,3.535,0,0,1,2.443,2.443l.369,1.293a.237.237,0,0,0,.456,0L7.6,10.068a3.535,3.535,0,0,1,2.443-2.443l1.293-.369a.237.237,0,0,0,0-.456Z"
																				transform="translate(476.336 772.459)"
																				fill="#8b0abe"
																			/>
																			<path
																				id="sparkle-2"
																				data-name="sparkle"
																				d="M7.055,4.735l-.666-.19A1.822,1.822,0,0,1,5.13,3.286l-.19-.666a.127.127,0,0,0-.235,0l-.19.666A1.822,1.822,0,0,1,3.255,4.545l-.666.19a.122.122,0,0,0,0,.235l.666.19A1.822,1.822,0,0,1,4.514,6.42l.19.666a.122.122,0,0,0,.235,0l.19-.666A1.822,1.822,0,0,1,6.389,5.161l.666-.19a.122.122,0,0,0,0-.235Z"
																				transform="translate(483.027 779.135)"
																				fill="#8b0abe"
																			/>
																		</g>
																	</svg>
																	<div className="ml-1 mr-2 ">AI Header</div>
																	<CustomSwitch
																		checked={this.state.post.ai_header}
																		className="event-filter__stations-switch"
																		changeOffHandleColor="#000000"
																		onChange={(checked) => {
																			this.changeHandler("ai_header", checked);
																			if (this.state.post.custom_header) {
																				this.changeHandler(
																					"custom_header",
																					false
																				);
																			}
																			if (checked) {
																				this.props.getHeaderMessage(
																					(
																						this.props.selEventIdList || []
																					).slice()
																				);
																			} else {
																				this.props.deleteHeaderMessage();
																			}
																		}}
																	/>
																</div>
																<div className="tip-content">
																	Artificial Intelligence is still new and we
																	encourage you to check before you post.
																</div>
															</div>
														</div>
														<textarea
															readOnly={
																(!this.state.post.custom_header &&
																	!this.state.post.ai_header) ||
																(this.state.post.ai_header &&
																	!this.state.post.custom_header_text)
															}
															rows="3"
															cols="250"
															type="text"
															className="form-control align-top"
															style={{ borderRadius: "10px" }}
															placeholder={
																this.state.post.custom_header
																	? this.state.defaultHeaderText
																	: this.state.post.ai_header
																	? "Please wait for the header to generate"
																	: "If left empty SportCompass got you covered!"
															}
															value={
																this.state.post.custom_header
																	? this.state.post.custom_header_text ||
																	  this.state.defaultHeaderText
																	: this.state.post.ai_header
																	? this.state.post.custom_header_text
																	: ""
															}
															onChange={(event) => {
																this.changeHandler(
																	"custom_header_text",
																	event.target.value
																);
															}}
														/>
													</div>
													<div className="form-group modal__custom-event--fs">
														<div className="modal__custom-event--fs mb-2 mt-2 d-flex align-items-center">
															<strong>Hashtags</strong>

															<div className="ml-auto mr-2">
																Custom Hashtags
															</div>
															<CustomSwitch
																checked={this.state.post.custom_footer}
																className="event-filter__stations-switch"
																changeOffHandleColor="#000000"
																onChange={(checked) => {
																	this.changeHandler("custom_footer", checked);
																}}
															/>
														</div>
														<textarea
															readOnly={!this.state.post.custom_footer}
															rows="3"
															col="250"
															type="text"
															className="form-control align-top"
															style={{ borderRadius: "10px" }}
															placeholder="Your custom footer"
															value={this.state.post.custom_footer_text}
															onChange={(event) => {
																this.changeHandler(
																	"custom_footer_text",
																	event.target.value
																);
															}}
														/>
													</div>
													<div className="d-flex">
														<div className="form-group d-block">
															<FileUploadShareEvents
																type="file"
																name="Add Photo"
																disabled={this.state.post.video}
																value={this.state.post.image}
																image={images("./share-events-upload.svg")}
																multiple={true}
																style="2px solid #1e4798"
																accept=".jpg, .jpeg, .png, .bmp"
																onChange={(event) => {
																	if (event && event.length > 0) {
																		const selectedImages = Array.from(
																			event
																		).filter((file) =>
																			file.type.match(/^image\//)
																		);

																		if (selectedImages.length > 0) {
																			const updatedImages = this.state.post
																				.image
																				? [
																						...this.state.post.image,
																						...selectedImages,
																				  ]
																				: selectedImages;
																			this.changeHandler(
																				"image",
																				updatedImages
																			);
																		}
																	}
																}}
															/>
														</div>
														<div className="form-group d-block">
															<FileUploadShareEvents
																type="file"
																name="Add Video"
																disabled={this.state.post.image}
																value={this.state.post.video}
																image={images("./share-events-upload.svg")}
																multiple={false}
																style="2px solid #1e4798"
																accept=".mp4"
																onChange={(event) => {
																	if (
																		event[0] &&
																		event[0].type === "video/mp4"
																	) {
																		this.changeHandler("video", event[0]);
																	} else {
																		console.log(
																			"Unsupported file type or empty event"
																		);
																	}
																}}
															/>
														</div>
													</div>
													{this.state.post.image ? (
														<div className="mb-3 d-flex">
															{this.state.post.image.map((img, index) => (
																<div className="mr-1" key={index}>
																	<UploadableImageFrame
																		src={img}
																		changeable={true}
																		changeImage={() =>
																			this.handleImageRemove(index)
																		}
																		width="85px"
																		height="85px"
																	/>
																</div>
															))}
														</div>
													) : null}
													{this.state.post.video ? (
														<div className="mb-3 d-flex">
															<UploadableVideoFrame
																src={this.state.post.video}
																removable={true}
																removeVideo={() =>
																	this.changeHandler("video", null)
																}
																width="85px"
																height="85px"
															/>
														</div>
													) : null}
													{this.state.post.image || this.state.post.video ? (
														<div
															className="form-group d-block mr-2"
															style={{ width: "50%" }}
														>
															<CustomButtonShareEvents
																type="button"
																name={this.attachmentPluralize(
																	"Delete Attachment"
																)}
																image={images("./red-trashcan.svg")}
																fill="#ff6565"
																style="2px solid #ff6565"
																onClick={() => {
																	this.changeHandler("image", null);
																	this.changeHandler("video", null);
																}}
															/>
														</div>
													) : null}
													{this.state.post.image &&
														(this.state.post.instagram_attachment ||
															this.state.post.attachment) &&
														!this.validateAttachmentLength(
															this.state.post.image
														) && (
															<div className="custom-event--fs ">
																<label
																	className="text-danger"
																	style={height_style[0]}
																>
																	Maximum limit reached! (Maximum allowed
																	images: 7).&nbsp;
																</label>
															</div>
														)}
													{this.state.post.image &&
														!this.validateAttachment(this.state.post.image) && (
															<div className="custom-event--fs ">
																<label
																	className="text-danger"
																	style={height_style[0]}
																>
																	Please enter a supported image format from the
																	following list: .jpg, .jpeg, .png, .bmp.&nbsp;
																</label>
															</div>
														)}
													{this.state.post.video &&
														!this.validateAttachment(this.state.post.video) && (
															<div className="custom-event--fs ">
																<label
																	className="text-danger"
																	style={height_style[0]}
																>
																	Please enter a supported video format from the
																	following list: .mp4.&nbsp;
																</label>
															</div>
														)}
													{this.state.post.image || this.state.post.video ? (
														<>
															<div
																className="form-group d-block"
																style={{ width: "50%" }}
															>
																<CustomSwitchShareEvents
																	name={`${this.attachmentPluralize(
																		"Attachment"
																	)} post on Facebook`}
																	image={
																		this.state.post.image
																			? images("./share-events-image.svg")
																			: images("./share-events-video.svg")
																	}
																	style="2px solid #1e4798"
																	checked={this.state.post.attachment}
																	showCheckmark={true}
																	fill="#1e4798"
																	onChange={(checked) => {
																		this.changeHandler("attachment", checked);
																	}}
																/>
															</div>

															<div
																className="form-group d-block"
																style={{ width: "50%" }}
															>
																<CustomSwitchShareEvents
																	name={`${this.attachmentPluralize(
																		"Attachment"
																	)} post on Instagram`}
																	image={
																		this.state.post.image
																			? images("./share-events-image.svg")
																			: images("./share-events-video.svg")
																	}
																	style="2px solid #ea31a4"
																	checked={this.state.post.instagram_attachment}
																	showCheckmark={true}
																	fill="#ea31a4"
																	onChange={(checked) => {
																		this.changeHandler(
																			"instagram_attachment",
																			checked
																		);
																	}}
																/>
															</div>

															<div
																className="form-group d-block"
																style={{ width: "50%" }}
															>
																<CustomSwitchShareEvents
																	name={`${this.attachmentPluralize(
																		"Attachment"
																	)} post on Instagram story`}
																	image={
																		this.state.post.image
																			? images("./share-events-image.svg")
																			: images("./share-events-video.svg")
																	}
																	style="2px solid #ea31a4"
																	checked={
																		this.state.post.attachment_story_instagram
																	}
																	showCheckmark={true}
																	fill="#ea31a4"
																	onChange={(checked) => {
																		this.changeHandler(
																			"attachment_story_instagram",
																			checked
																		);
																	}}
																/>
															</div>
														</>
													) : null}
													<div
														className="form-group d-flex align-items-center"
														style={{ width: "232.36px", height: "43.61px" }}
													>
														<CustomPostSelector
															images={this.state.post.poster_options}
															selected={this.state.post.poster_setting}
															setSelected={(selected) => {
																this.changeHandler("poster_setting", selected);
															}}
														/>
													</div>
													{this.state.post.video_post ||
													(this.state.post.instagram &&
														(this.state.post.instagram_video_post ||
															this.state.post.video_story_instagram)) ? (
														<div
															className="form-group d-flex align-items-center"
															style={{
																width: "232.36px",
																height: "43.61px",
															}}
														>
															<CustomSwitchShareEvents
																name={`Short Video`}
																image={images("./share-events-video.svg")}
																style="2px solid #ea31a4"
																checked={this.state.post.is_short_video}
																showCheckmark={false}
																fill="#ea31a4"
																onChange={(checked) => {
																	this.changeHandler("is_short_video", checked);
																}}
															/>
														</div>
													) : null}

													<div className="form-group d-flex align-items-center">
														<input
															className="ch-box ml-1"
															type="checkbox"
															checked
															onChange={() => {}}
														/>
														<label className="text-xl mb-0 ml-2">
															Post {this.pluralize("event")} on your{" "}
															<strong>bar's website</strong>
														</label>
													</div>
												</div>

												<div className="col-md-4 bg-light">
													<div
														className="d-flex flex-column bg-white mt-3 pt-3 pb-3 pl-3 pr-3"
														style={{
															border: "2px solid #dadee2",
															borderRadius: "10px",
														}}
													>
														<div className="d-flex align-items-center">
															<img
																style={{ widht: "26px", height: "26px" }}
																src={images("./share-events-facebook.png")}
																alt="facebook"
															></img>
															<strong className="ml-2">Facebook</strong>
														</div>
														<div className="pt-2">
															<strong>Post</strong>
														</div>
														<div className="switch-container">
															<CustomSwitchShareEvents
																name="Post as video"
																isDisabled={
																	this.state.post.video || this.state.post.image
																}
																image={images("./share-events-video.svg")}
																style="2px solid #1e4798"
																checked={this.state.post.video_post}
																showCheckmark={!this.state.post.fbVideoErr}
																fill="#1e4798"
																onChange={(checked) => {
																	this.changeHandler("video_post", checked);
																}}
															/>
															{this.state.post.fbVideoErr &&
																this.tlTipErrorManager(
																	this.state.post.fbVideoErr
																)}
														</div>
														<div className="switch-container">
															<CustomSwitchShareEvents
																name="Post as image"
																isDisabled={this.state.post.video}
																image={images("./share-events-image.svg")}
																style="2px solid #1e4798"
																checked={this.state.post.image_post}
																showCheckmark={!this.state.post.fbImageErr}
																fill="#1e4798"
																onChange={(checked) => {
																	this.changeHandler("image_post", checked);
																}}
															/>
															{this.state.post.fbImageErr &&
																this.tlTipErrorManager(
																	this.state.post.fbImageErr
																)}
														</div>
														<CustomSwitchShareEvents
															name="Post as text"
															image={images("./share-events-text.svg")}
															style="2px solid #1e4798"
															checked={this.state.post.text_post}
															showCheckmark={true}
															fill="#1e4798"
															onChange={(checked) => {
																this.changeHandler("text_post", checked);
															}}
														/>
													</div>

													<div
														className="d-flex flex-column bg-white mt-3 mb-3 pt-3 pb-3 pl-3 pr-3"
														style={{
															border: "2px solid #dadee2",
															borderRadius: "10px",
														}}
													>
														<div className="d-flex align-items-center">
															<img
																style={{ widht: "26px", height: "26px" }}
																src={images("./share-events-instagram.png")}
																alt="instagram"
															></img>
															<strong className="ml-2">Instagram</strong>
														</div>
														<div className="pt-2">
															<strong>Story</strong>
														</div>
														<div className="switch-container">
															<CustomSwitchShareEvents
																name="Post as video story"
																isDisabled={
																	this.state.post.video || this.state.post.image
																}
																image={images("./share-events-video.svg")}
																style="2px solid #ea31a4"
																checked={this.state.post.video_story_instagram}
																showCheckmark={true}
																fill="#ea31a4"
																onChange={(checked) => {
																	this.changeHandler(
																		"video_story_instagram",
																		checked
																	);
																}}
															/>
														</div>
														<div className="switch-container">
															<CustomSwitchShareEvents
																name="Post as image story"
																isDisabled={this.state.post.video}
																image={images("./share-events-image.svg")}
																style="2px solid #ea31a4"
																checked={this.state.post.image_story_instagram}
																showCheckmark={true}
																fill="#ea31a4"
																onChange={(checked) => {
																	this.changeHandler(
																		"image_story_instagram",
																		checked
																	);
																}}
															/>
														</div>
														<div className="pt-2">
															<strong>Post</strong>
														</div>
														<div className="switch-container">
															<CustomSwitchShareEvents
																name="Post as video"
																isDisabled={
																	this.state.post.video || this.state.post.image
																}
																image={images("./share-events-video.svg")}
																style="2px solid #ea31a4"
																checked={this.state.post.instagram_video_post}
																showCheckmark={!this.state.post.igVideoErr}
																fill="#ea31a4"
																onChange={(checked) => {
																	this.changeHandler(
																		"instagram_video_post",
																		checked
																	);
																}}
															/>
															{this.state.post.igVideoErr &&
																this.tlTipErrorManager(
																	this.state.post.igVideoErr
																)}
														</div>
														<div className="switch-container">
															<CustomSwitchShareEvents
																name="Post as image"
																isDisabled={this.state.post.video}
																image={images("./share-events-image.svg")}
																style="2px solid #ea31a4"
																checked={this.state.post.instagram_image_post}
																showCheckmark={!this.state.post.igImageErr}
																fill="#ea31a4"
																onChange={(checked) => {
																	this.changeHandler(
																		"instagram_image_post",
																		checked
																	);
																}}
															/>
															{this.state.post.igImageErr &&
																this.tlTipErrorManager(
																	this.state.post.igImageErr
																)}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="modal-footer p-3">
											<Button
												type="button"
												className="button-warning--modal p-1 mr-2"
												style={{ height: "50px" }}
												onClick={() => {
													this.cancelModal();
													this.props.deleteHeaderMessage();
												}}
											>
												Cancel
											</Button>
											<Button
												type="submit"
												className="button-success--modal p-1 mr-3"
												style={{ height: "50px", width: "150px" }}
												disabled={isSubmitting || !this.validatePost()}
											>
												{this.pluralize("Share Event")}
											</Button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		header_message: state.header_message,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showNotice: (message, success) =>
			dispatch(actionCreators.showNotice(message, success)),
		deleteHeaderMessage: () => {
			dispatch(actionCreators.deleteHeaderMessage());
		},
		getHeaderMessage: (params) =>
			dispatch(actionCreators.getHeaderMessage(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PostEditorPopup);
