import React, { Fragment, useEffect, useState } from "react";
import CustomSwitch from "../../components/CustomSwitch";
import CustomInputDate from "../../components/CustomInputDate";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { Button } from "react-bootstrap-buttons";
import * as actionCreators from "../../modules/actions";
import * as api from "../../utils/api";
import { connect } from "react-redux";
import PosterSelector from "../../components/PosterSelector";
import TextareaAutosize from "react-textarea-autosize";
import { UploadableImageFrame } from "../../components/UploadableImageFrame";
import CustomInputTime from "../../components/CustomInputTime";
import { setHours, setMinutes } from "date-fns";
import { Emoji, getEmojiDataFromNative } from "emoji-mart";
import data from "emoji-mart/data/facebook.json";
import { dtzWithLocalZone, dtLocalToDtz } from "../../utils/utils";
import CustomLoader from "../../components/CustomLoader";
import VideoFrameUploadable from "../../components/VideoFrameUploadable";

const reactStringReplace = require("react-string-replace");
const width_style = { width: "350px" };
const height_style = [
	{ height: "85px" },
	{ height: "25px" },
	{ height: "30px" },
];

const images = require.context("../../assests/images/img", true);

const Checkbox = (props) => (
	<div className="d-flex align-items-center">
		<span className="text-left fs-body3 m-0 d-block float-left mr-1">
			{props.text}
		</span>
		<div className="checkbox-wrapper d-flex align-items-center d-block float-left">
			<input
				type="checkbox"
				name="select all checkbox"
				onChange={props.onChange}
				checked={props.checked}
			/>
			<span className="checkmark"></span>
		</div>
	</div>
);

const DAYS_ARRAY = [0, 1, 2, 3, 4, 5, 6];

const EditableArea = (props) => {
	return (
		<TextareaAutosize
			role="textbox"
			style={{ width: "100%", border: "none", outline: "none" }}
			className="px-1"
			value={props.value}
			onChange={props.onChange}
		/>
	);
};

const UneditableArea = (props) => {
	return (
		<div
			style={{
				backgroundColor: "#e9ecef",
				height: "fit-content",
				whiteSpace: "pre-wrap",
			}}
			className="px-1 w-100 text-break"
			unselectable="on"
		>
			{props.text || props.children}
		</div>
	);
};

// const postingOptionsErrorMessages = {
// 	attVideoErr:
// 		"Facebook's guidelines do not allow videos to be posted together with another videos or images.",
// 	imageErr:
// 		"Facebook's guidelines do not allow images to be posted together with videos.",
// 	videoErr:
// 		"Facebook's guidelines do not allow videos to be posted together with images.",
// };

const ElementDetails = (props) => {
	// const [showEmoji, setShowEmoji] = useState(false);
	const {
		elements,
		activeElement,
		setElements,
		setActiveElement,
		createF,
		updateF,
		deleteF,
		tab,
		posterOptions,
		defaultPosterOption,
		fbDefaults,
	} = props;

	const [postPreview, setPostPreview] = useState("");
	const [loadingPreview, setLoadingPreview] = useState(true);

	useEffect(() => {
		tab === "posts" && getPostPreview();
	}, [activeElement]);

	const changeHandler = (field, fieldValue) => {
		const object = elements[activeElement];
		object[field] = fieldValue;
		const objects = { ...elements };
		objects[activeElement] = object;
		setElements(objects);
		tab === "posts" && field !== "header" && getPostPreview();
	};

	// useEffect(() => {
	// 	const {
	// 		image_post,
	// 		video_post,
	// 		attachment,
	// 		video_attachment_facebook,
	// 		instagram_image_post,
	// 		instagram_video_post,
	// 		image_story_instagram,
	// 		video_story_instagram,
	// 		instagram_attachment,
	// 		video_attachment_instagram,
	// 		attachment_story_instagram,
	// 		video_attachment_story_instagram,
	// 	} = elements[activeElement];
	// 	const postingOptionErrorChanges = {};

	// 	// if (!video_post && image_post) {
	// 	// 	postingOptionErrorChanges.fbVideoErr =
	// 	// 		postingOptionsErrorMessages.videoErr;
	// 	// }

	// 	// if (video_post && !image_post) {
	// 	// 	postingOptionErrorChanges.fbImageErr =
	// 	// 		postingOptionsErrorMessages.imageErr;
	// 	// }

	// 	// if (!attachment && video_attachment_facebook) {
	// 	// 	postingOptionErrorChanges.fbVideoErr =
	// 	// 		postingOptionsErrorMessages.videoErr;
	// 	// }

	// 	// if (attachment && !video_attachment_facebook) {
	// 	// 	postingOptionErrorChanges.fbImageErr =
	// 	// 		postingOptionsErrorMessages.imageErr;
	// 	// }

	// 	// if (!image_story_instagram && video_story_instagram) {
	// 	// 	postingOptionErrorChanges.fbVideoErr =
	// 	// 		postingOptionsErrorMessages.videoErr;
	// 	// }

	// 	// if (image_story_instagram && !video_story_instagram) {
	// 	// 	postingOptionErrorChanges.fbImageErr =
	// 	// 		postingOptionsErrorMessages.imageErr;
	// 	// }

	// 	// if (!instagram_attachment && video_attachment_instagram) {
	// 	// 	postingOptionErrorChanges.fbVideoErr =
	// 	// 		postingOptionsErrorMessages.videoErr;
	// 	// }

	// 	// if (instagram_attachment && !video_attachment_instagram) {
	// 	// 	postingOptionErrorChanges.fbImageErr =
	// 	// 		postingOptionsErrorMessages.imageErr;
	// 	// }

	// 	// if (!instagram_video_post && instagram_image_post) {
	// 	// 	postingOptionErrorChanges.igVideoErr =
	// 	// 		postingOptionsErrorMessages.videoErr;
	// 	// }
	// 	// if (instagram_video_post && !instagram_image_post) {
	// 	// 	postingOptionErrorChanges.igImageErr =
	// 	// 		postingOptionsErrorMessages.imageErr;
	// 	// }

	// 	// if (!attachment_story_instagram && video_attachment_story_instagram) {
	// 	// 	postingOptionErrorChanges.igVideoErr =
	// 	// 		postingOptionsErrorMessages.videoErr;
	// 	// }
	// 	// if (attachment_story_instagram && !video_attachment_story_instagram) {
	// 	// 	postingOptionErrorChanges.igImageErr =
	// 	// 		postingOptionsErrorMessages.imageErr;
	// 	// }

	// 	if (Object.keys(postingOptionErrorChanges).length > 0) {
	// 		elements[activeElement] = {
	// 			...elements[activeElement],
	// 			// ...postingOptionErrorChanges,
	// 		};
	// 	}
	// });

	useEffect(() => {
		const {
			video,
			image,
			image_post,
			video_post,
			attachment,
			video_attachment_facebook,
			instagram_image_post,
			instagram_video_post,
			image_story_instagram,
			video_story_instagram,
			instagram_attachment,
			video_attachment_instagram,
			attachment_story_instagram,
			video_attachment_story_instagram,
		} = elements[activeElement];

		const stateChanges = { ...elements[activeElement] };

		const resetErrors = (errors) => {
			for (const error of errors) {
				stateChanges[error] = null;
			}
		};

		const resetPostingOptions = () => {
			stateChanges.image_post = false;
			stateChanges.attachment = false;

			stateChanges.instagram_image_post = false;
			stateChanges.instagram_attachment = false;
			stateChanges.attachment_story_instagram = false;
			resetErrors(["fbVideoErr", "fbImageErr", "igVideoErr", "igImageErr"]);
		};

		const imageResetOptions = () => {
			stateChanges.video_post = false;
			stateChanges.video_attachment_facebook = false;
			stateChanges.video_story_instagram = false;
			stateChanges.instagram_video_post = false;
			stateChanges.video_attachment_instagram = false;
			stateChanges.video_attachment_story_instagram = false;

			resetErrors(["fbVideoErr", "fbImageErr", "igVideoErr", "igImageErr"]);
		};
		//image_post and video_post
		if (image_post && video_post) {
			stateChanges.video_post = false;
			// stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
			stateChanges.fbImageErr = null;
		}

		if (video_post && image_post) {
			stateChanges.image_post = false;
			// stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
			stateChanges.fbVideoErr = null;
		}

		if (!video_post && image_post) {
			// stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
		}

		if (video_post && !image_post) {
			// stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
		}

		if (!video_post && !image_post) {
			stateChanges.fbVideoErr = null;
			stateChanges.fbImageErr = null;
		}

		// attachment and video_attachment_facebook
		if (attachment && video_attachment_facebook) {
			stateChanges.video_attachment_facebook = false;
			// stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
			stateChanges.fbImageErr = null;
		}

		if (!image) {
			stateChanges.attachment = false;
		}

		if (video_attachment_facebook && attachment) {
			stateChanges.attachment = false;
			// stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
			stateChanges.fbVideoErr = null;
		}

		if (!video_attachment_facebook && attachment) {
			// stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
		}

		if (video_attachment_facebook && !attachment) {
			// stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
		}

		if (!video_attachment_facebook && !attachment) {
			stateChanges.fbVideoErr = null;
			stateChanges.fbImageErr = null;
		}

		// instagram_image_post and instagram_video_post
		if (instagram_image_post && instagram_video_post) {
			stateChanges.instagram_video_post = false;
			// stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
			stateChanges.fbImageErr = null;
		}

		if (instagram_video_post && instagram_image_post) {
			stateChanges.instagram_image_post = false;
			// stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
			stateChanges.fbVideoErr = null;
		}

		if (!instagram_video_post && instagram_image_post) {
			// stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
		}

		if (instagram_video_post && !instagram_image_post) {
			// stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
		}

		if (!instagram_video_post && !instagram_image_post) {
			stateChanges.fbVideoErr = null;
			stateChanges.fbImageErr = null;
		}

		// instagram_attachment and video_attachment_instagram
		if (instagram_attachment && video_attachment_instagram) {
			stateChanges.video_attachment_instagram = false;
			// stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
			stateChanges.fbImageErr = null;
		}

		if (video_attachment_instagram && instagram_attachment) {
			stateChanges.instagram_attachment = false;
			// stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
			stateChanges.fbVideoErr = null;
		}

		if (!video_attachment_instagram && instagram_attachment) {
			// stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
		}

		if (video_attachment_instagram && !instagram_attachment) {
			// stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
		}

		if (!video_attachment_instagram && !instagram_attachment) {
			stateChanges.fbVideoErr = null;
			stateChanges.fbImageErr = null;
		}

		// attachment_story_instagram and video_attachment_story_instagram
		if (attachment_story_instagram && video_attachment_story_instagram) {
			stateChanges.video_attachment_story_instagram = false;
			// stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
			stateChanges.fbImageErr = null;
		}

		if (video_attachment_story_instagram && attachment_story_instagram) {
			stateChanges.attachment_story_instagram = false;
			// stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
			stateChanges.fbVideoErr = null;
		}

		if (!video_attachment_story_instagram && attachment_story_instagram) {
			// stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
		}

		if (video_attachment_story_instagram && !attachment_story_instagram) {
			// stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
		}

		if (!video_attachment_story_instagram && !attachment_story_instagram) {
			stateChanges.fbVideoErr = null;
			stateChanges.fbImageErr = null;
		}

		if (image) {
			// const imageErrorMessage = postingOptionsErrorMessages.imageErr;
			// const imageOptionsErrors = ["fbVideoErr", "igVideoErr"];

			imageResetOptions();
			// for (const option of imageOptionsErrors) {
			// 	stateChanges[option] = imageErrorMessage;
			// }
		}

		if (video) {
			// const videoErrorMessage = postingOptionsErrorMessages.attVideoErr;
			// const videoOptionsErrors = [
			// 	"fbVideoErr",
			// 	"fbImageErr",
			// 	"igVideoErr",
			// 	"igImageErr",
			// ];

			resetPostingOptions();
			// for (const option of videoOptionsErrors) {
			// 	stateChanges[option] = videoErrorMessage;
			// }
		}
		elements[activeElement] = stateChanges;
	}, [elements, activeElement]);

	const getPostPreview = () => {
		setLoadingPreview(true);
		api.endpoints.get_post_preview(
			elements[activeElement],
			(res) => {
				setLoadingPreview(false);
				setPostPreview(formatPostPreview(res));
			},
			(err) => {
				console.log(err);
			}
		);
	};

	const formatPostPreview = (preview) => {
		var formattedPreview = preview;

		// remove header
		if (elements[activeElement].header) {
			formattedPreview = formattedPreview.replace(
				elements[activeElement].header,
				""
			);
		}

		// remove blank lines from beginning
		formattedPreview = formattedPreview.replace(/^\s+|\s+$/g, "");

		return convertEmojis(formattedPreview);
	};

	const createElement = () => {
		props.showNotice("Creating", true, { loading: true });
		let smp = { ...elements[activeElement] };
		delete smp.fbVideoErr;
		delete smp.fbImageErr;
		delete smp.igVideoErr;
		delete smp.igImageErr;
		delete smp["id"];
		createF(
			smp,
			(res) => {
				const objects = { ...elements };
				objects[res.id] = res;
				setElements(objects);
				setActiveElement(res.id);
				let objects2 = { ...objects };
				delete objects2["Draft"];
				setElements(objects2);
				if (props.loadPosts) props.loadPosts();
				let message =
					res.duplicates === true
						? "Your posting rule has been created. It looks like you have some duplicates. Please check your scheduled posts for conflicts."
						: "Created";
				props.showNotice(message, true);
				getPostPreview();
			},
			(err) => {
				props.showNotice(err.response.data.message, false);
			}
		);
	};

	const updateElement = () => {
		props.showNotice("Updating", true, { loading: true });
		updateF(
			elements[activeElement],
			(res) => {
				if (props.loadPosts) props.loadPosts();
				let message =
					res.duplicates === true
						? "Your posting rule has been updated. It looks like you have some duplicates. Please check your scheduled posts for conflicts."
						: "Updated";
				props.showNotice(message, true);
				tab === "posts" && getPostPreview();
			},
			(err) => {
				props.showNotice(err.response.data.message, false, {
					loading: false,
				});
			}
		);
	};

	const removeElementLocally = (id) => {
		setActiveElement(null);
		const objects = { ...elements };
		delete objects[id];
		setElements(objects);
	};

	const deleteElement = () => {
		if (elements[activeElement].id == "Draft") {
			removeElementLocally("Draft");
		} else {
			deleteF(elements[activeElement].id, (res) => {
				removeElementLocally(elements[activeElement].id);
				if (props.loadPosts) props.loadPosts();
			});
		}
	};

	const changeImage = (image) => {
		changeHandler("image", image);
		if (image) {
			changeHandler("attachment", true);
			changeHandler("video_post", false);
			changeHandler("video_attachment_facebook", false);
			if (elements[activeElement].instagram == true)
				changeHandler("instagram_video_post", false);
			changeHandler("image_story_instagram", true);
			changeHandler("video_story_instagram", false);
			changeHandler("instagram_attachment", true);
			changeHandler("video_attachment_instagram", false);
			changeHandler("attachment_story_instagram", true);
			changeHandler("video_attachment_story_instagram", false);
		}
	};

	const changeVideo = (video) => {
		changeHandler("video", video);
		if (video) {
			changeHandler("image_post", false);
			changeHandler("video_post", false);
			changeHandler("attachment", false);
			changeHandler("video_attachment_facebook", true);

			if (elements[activeElement].instagram == true) {
				changeHandler("instagram_image_post", false);
				changeHandler("instagram_video_post", false);

				changeHandler("image_story_instagram", false);
				changeHandler("video_story_instagram", false);

				changeHandler("instagram_attachment", false);
				changeHandler("video_attachment_instagram", true);

				changeHandler("attachment_story_instagram", false);
				changeHandler("video_attachment_story_instagram", true);
			}
		}
	};

	function convertEmojis(string) {
		const regex = /([🇦-🇿]{2}|🏴󠁧󠁢󠁥󠁮󠁧󠁿|🏴󠁧󠁢󠁳󠁣󠁴󠁿󠁧󠁢󠁥󠁮|🏴󠁧󠁢󠁷󠁬󠁳󠁿󠁧󠁢󠁳󠁣󠁴󠁿󠁧󠁢|⏰|🎤)/gu;

		return reactStringReplace(string, regex, (match, i) => {
			let emojiData = getEmojiDataFromNative(match, "facebook", data);
			if (emojiData == null) return match;
			return <Emoji key={i} emoji={emojiData} set="facebook" size={16} />;
		});
	}

	const validateAttachment = (image) => {
		const valid_content_type = [
			"image/jpg",
			"image/jpeg",
			"image/png",
			"image/bmp",
			"video/mp4",
		];
		const valid_extension = [
			".jpg",
			".jpeg",
			".png",
			".bmp",
			".mp4",
			".JPG",
			".JPEG",
			".PNG",
			".BMP",
			".MP4",
		];

		if (image instanceof String || typeof image === "string") return true;
		if (!(image instanceof File)) return false;

		let type = image.type;
		let extension = image.name.match(/\.[0-9a-z]+$/i);
		if (type == null || extension == null) return false;
		if (
			valid_content_type.includes(type) &&
			valid_extension.includes(extension[0])
		)
			return true;
		else return false;
	};

	const validatePost = () => {
		if (
			(elements[activeElement].image &&
				!validateAttachment(elements[activeElement].image)) ||
			(elements[activeElement].video &&
				!validateAttachment(elements[activeElement].video))
		)
			return false;
		if (
			elements[activeElement].text_post ||
			elements[activeElement].image_post ||
			(elements[activeElement].image && elements[activeElement].attachment) ||
			elements[activeElement].instagram_image_post ||
			elements[activeElement].image_story_instagram ||
			(elements[activeElement].image &&
				elements[activeElement].instagram_attachment) ||
			elements[activeElement].video_post ||
			elements[activeElement].video_attachment_facebook ||
			elements[activeElement].instagram_video_post ||
			elements[activeElement].video_story_instagram ||
			elements[activeElement].video_attachment_instagram ||
			elements[activeElement].attachment_story_instagram ||
			elements[activeElement].video_attachment_story_instagram
		)
			return true;
		return false;
	};

	const noPostTimes = [
		setHours(setMinutes(new Date(), 0), 0),
		setHours(setMinutes(new Date(), 0), 1),
		setHours(setMinutes(new Date(), 0), 2),
		setHours(setMinutes(new Date(), 0), 3),
		setHours(setMinutes(new Date(), 0), 4),
		setHours(setMinutes(new Date(), 0), 5),
	];

	return (
		<div className="bg-white w-100 h-100 ">
			<CustomLoader loading={!fbDefaults}>
				<div className="rightSide__elementData w-100 overflow-auto">
					<div className="w-75 pl-2 pt-2 d-flex flex-column">
						{tab === "posts" && (
							<div
								style={{ width: "200px" }}
								className="d-flex flex-column form-group ml-3"
							>
								<label className="fs-body1">Post Date:</label>
								<DatePicker
									selected={dtzWithLocalZone(
										moment(elements[activeElement].post_date).tz(props.timezone)
									)}
									showTimeSelect
									dateFormat={
										props.time_format === "24H"
											? "dd/MM/yyyy HH:mm"
											: "dd/MM/yyyy hh:mm aa"
									}
									timeFormat={
										props.time_format === "24H" ? "HH:mm" : "hh:mm aa"
									}
									strictParsing={true}
									name="event_date"
									onChange={(date) => {
										if (date != null) {
											changeHandler(
												"post_date",
												dtLocalToDtz(date, props.timezone)
											);
										}
									}}
									excludeTimes={noPostTimes}
									useWeekdaysShort={true}
									monthsShown={1}
									timeIntervals={60}
									customInput={<CustomInputDate />}
									minDate={new Date()}
								/>
							</div>
						)}
						{tab === "postingRules" && (
							<Fragment>
								<div className="d-flex align-items-center form-group ml-3">
									<span className="fs-body1">Name:&nbsp;&nbsp;</span>
									<input
										className="form-control"
										value={elements[activeElement].name}
										onChange={(event) => {
											changeHandler("name", event.target.value);
										}}
									/>
								</div>
								<div className="d-flex align-items-center form-group ml-3">
									<span className="fs-body1">Time:&nbsp;&nbsp;</span>
									<DatePicker
										selected={dtzWithLocalZone(
											moment(elements[activeElement].post_time).tz(
												props.timezone
											)
										)}
										dateFormat={
											props.time_format === "24H" ? "HH:mm" : "hh:mm aa"
										}
										timeFormat={
											props.time_format === "24H" ? "HH:mm" : "hh:mm aa"
										}
										onChange={(date) => {
											if (date !== null) {
												changeHandler(
													"post_time",
													dtLocalToDtz(date, props.timezone)
												);
											}
										}}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={60}
										timeCaption=""
										excludeTimes={noPostTimes}
										customInput={<CustomInputTime />}
									/>
								</div>
								<div className="d-flex flex-column form-group ml-3">
									<div className="d-flex mb-1">
										<span className="fs-body1 mr-2">Posting days:</span>
										<Checkbox
											onChange={(event) => {
												let days = [];
												if (event.target.checked) days = DAYS_ARRAY;
												changeHandler("days", days);
											}}
											checked={
												elements[activeElement].days.sort().join(",") ===
												DAYS_ARRAY.join(",")
											}
											text="All"
										/>
									</div>
									<div className="d-flex flex-row space-between ml-3">
										{moment.weekdays().map((day, i) => {
											return (
												<Checkbox
													key={day}
													onChange={(event) => {
														let days = elements[activeElement].days;
														if (event.target.checked) days.push(i);
														else days = days.filter((day) => day !== i);
														changeHandler("days", days);
													}}
													checked={elements[activeElement].days.includes(i)}
													text={day}
												/>
											);
										})}
									</div>
								</div>
							</Fragment>
						)}
						{tab === "postingRules" && (
							<Fragment>
								<div className="fs-body1 ml-3">Header:</div>
								<div className="form-group ml-3">
									{tab === "postingRules" && (
										<div className="float-left fs-body1 pr-4">
											<h5 className="fs-body1 feature-name pr-1">
												Autogenerate:
											</h5>
											<CustomSwitch
												checked={elements[activeElement].autogenerated_header}
												className="event-filter__stations-switch"
												onChange={(checked) => {
													changeHandler(
														"autogenerated_header",
														checked
													);
													if (checked) {
														changeHandler(
															"ai_header",
															false
														);
													}
												}}
											/>
											<br />
											<h5 className="fs-body1 feature-name pr-1">AI Header:</h5>
											<CustomSwitch
												checked={elements[activeElement].ai_header}
												className="event-filter__stations-switch"
												onChange={(checked) => {
													changeHandler(
														"ai_header",
														checked
													);
													if (checked) {
														changeHandler(
															"autogenerated_header",
															false
														);
													}
												}}
											/>
										</div>
									)}
									{!(
										(tab === "postingRules" &&
											elements[activeElement]
												.autogenerated_header) ||
										elements[activeElement].ai_header
									) && (
										<div className="d-flex flex-column w-100">
											<textarea
												rows="3"
												col="250"
												type="text"
												className="form-control align-top"
												placeholder="Your custom header"
												value={elements[activeElement].header}
												onChange={(event) => {
													changeHandler("header", event.target.value);
												}}
											/>
											{/* <div className="align-self-end position-relative">
												<img
												style={{ width: "25px", height: "25px" }}
												className="bg-white"
												src={images("./img/smile-emoji-button.svg")}
												onClick={() => setShowEmoji(!showEmoji)}
												/>
												{showEmoji &&
												<Picker
													style={{ position: 'absolute', top: '26px', right: '0px', zIndex: "10" }}
													set='facebook'
													onSelect={(emoji) => changeHandler(
													"header",
													elements[activeElement].header + emoji.native
													)}
													showPreview={false}
													title='Pick your emoji'
													emoji=""
												/>
												}
											</div> */}
										</div>
									)}
								</div>
							</Fragment>
						)}
						{tab === "posts" && (
							<div
								style={{
									minHeight: "180px",
									overflow: "auto",
									resize: "vertical",
								}}
								unselectable="on"
								className="form-control px-0 ml-3"
							>
								<EditableArea
									value={elements[activeElement].header}
									onChange={(event) => {
										changeHandler("header", event.target.value);
									}}
								/>
								{elements[activeElement].text_post && (
									<UneditableArea>
										<CustomLoader loading={loadingPreview}>
											{postPreview}
										</CustomLoader>
									</UneditableArea>
								)}
							</div>
						)}

						<span className="mr-3 modal__custom-event--fs mt-3 ml-3 mb-3">
							Selected:{" "}
							{
								posterOptions[
									elements[activeElement].poster_option_id == null
										? defaultPosterOption
										: elements[activeElement].poster_option_id
								].name
							}
						</span>
						<div
							className="form-group d-flex align-items-center ml-3"
							style={height_style[1]}
						>
							<div className="float-left fs-body1 pr-4">
								<PosterSelector
									images={posterOptions}
									selected={
										elements[activeElement].poster_option_id == null
											? defaultPosterOption
											: elements[activeElement].poster_option_id
									}
									setSelected={(selected) => {
										changeHandler("poster_option_id", selected);
									}}
								/>
							</div>
						</div>
						<div className="d-flex flex-row">
							<div>
								<span className="mb-2 ml-3">Images: </span>
								<div className="d-flex flex-row ml-3">
									{(elements[activeElement].image_post ||
										elements[activeElement].instagram_image_post ||
										elements[activeElement].image_story_instagram) && (
										<UploadableImageFrame
											src={
												posterOptions[
													elements[activeElement].poster_option_id == null
														? defaultPosterOption
														: elements[activeElement].poster_option_id
												].img
											}
											removable={false}
										/>
									)}

									{elements[activeElement].attachment ||
									elements[activeElement].instagram_attachment ||
									elements[activeElement].attachment_story_instagram ? (
										<UploadableImageFrame
											src={elements[activeElement].image}
											changeable={true}
											changeImage={changeImage}
										/>
									) : (
										<>
											{(elements[activeElement].image = null)}
											<UploadableImageFrame
												src={null}
												changeable={true}
												changeImage={changeImage}
											/>
										</>
									)}
								</div>
								{elements[activeElement].image &&
									!validateAttachment(elements[activeElement].image) && (
										<div className="custom-event--fs ml-3">
											<label className="text-danger" style={height_style[1]}>
												Please enter a supported image format from the following
												list: .jpg, .jpeg, .png, .bmp.&nbsp;
											</label>
										</div>
									)}
							</div>

							<div>
								<span className="mb-2 ml-3">Video: </span>
								<div className="d-flex flex-row ml-3">
									{elements[activeElement].video_attachment_facebook ||
									(elements[activeElement].instagram &&
										elements[activeElement].video_attachment_story_instagram) ||
									elements[activeElement].video_attachment_instagram ? (
										<VideoFrameUploadable
											src={elements[activeElement].video}
											changeable={true}
											changeVideo={changeVideo}
											width="80px"
											height="80px"
										/>
									) : (
										<>
											{(elements[activeElement].video = null)}
											<VideoFrameUploadable
												src={null}
												changeable={true}
												changeVideo={changeVideo}
												width="80px"
												height="80px"
											/>
										</>
									)}
								</div>
								{elements[activeElement].video &&
									!validateAttachment(elements[activeElement].video) && (
										<div className="custom-event--fs ml-3">
											<label className="text-danger" style={height_style[1]}>
												Please enter a supported video format from the following
												list: .mp4.&nbsp;
											</label>
										</div>
									)}
							</div>
						</div>
						<br />
						<p className="ml-3" style={{ marginBottom: "0" }}>
							Auto-generated content (games & events):{" "}
						</p>
						<div className="d-flex flex-row ml-3">
							<div className="section">
								<div
									className="d-flex flex-column bg-white mt-3 pt-3 pb-3 pl-3 pr-3"
									style={{
										border: "2px solid #dadee2",
										borderRadius: "10px",
										height: "480px",
									}}
								>
									<div
										style={{
											paddingLeft: "15px",
											paddingBottom: "10px",
										}}
									>
										<img
											style={{
												widht: "26px",
												height: "26px",
											}}
											src={images("./share-events-facebook.png")}
											alt="facebook"
										></img>
										<strong className="ml-2">Facebook</strong>
									</div>
									<div
										style={{
											paddingLeft: "15px",
											paddingBottom: "5px",
										}}
									>
										<strong>Post</strong>
									</div>
									<div
										className="form-group d-block ml-3 "
										style={height_style[1]}
									>
										<div
											className="float-left fs-body1 pr-4"
											style={width_style}
										>
											<img
												style={{
													widht: "23px",
													height: "23px",
												}}
												src={images("./share-events-text.svg")}
												alt="facebook"
											></img>
											<h5 className="fs-body1 feature-name pr-1">
												&nbsp; Post as text
											</h5>
											<CustomSwitch
												checked={elements[activeElement].text_post}
												className="event-filter__stations-switch"
												onChange={(checked) => {
													changeHandler("text_post", checked);
												}}
											/>
										</div>
									</div>
									<div
										className="form-group d-block ml-3"
										style={height_style[1]}
									>
										<div
											className="float-left fs-body1 pr-4"
											style={width_style}
										>
											<img
												style={{
													widht: "23px",
													height: "23px",
												}}
												src={images("./share-events-image.svg")}
												alt="facebook"
											></img>
											<h5 className="fs-body1 feature-name pr-1">
												&nbsp; Post as image
											</h5>
											<CustomSwitch
												checked={elements[activeElement].image_post}
												selectable={!elements[activeElement].video}
												disabled={elements[activeElement].video_post}
												className="event-filter__stations-switch"
												onChange={(checked) => {
													changeHandler("image_post", checked);
													if (checked) {
														changeHandler("video_post", false);
													}
												}}
											/>
										</div>
									</div>
									<div
										className="form-group d-block ml-3"
										style={height_style[1]}
									>
										<div
											className="float-left fs-body1 pr-4"
											style={width_style}
										>
											<img
												style={{
													widht: "23px",
													height: "23px",
												}}
												src={images("./share-events-video.svg")}
												alt="facebook"
											></img>
											<h5 className="fs-body1 feature-name pr-1">
												&nbsp; Post as video
											</h5>
											<CustomSwitch
												checked={elements[activeElement].video_post}
												selectable={
													!elements[activeElement].video &&
													!elements[activeElement].image
												}
												disabled={elements[activeElement].image_post}
												className="event-filter__stations-switch"
												onChange={(checked) => {
													changeHandler("video_post", checked);
													if (checked) {
														changeHandler("image_post", false);
													}
												}}
											/>
										</div>
									</div>
									<div
										style={{
											paddingLeft: "15px",
											paddingBottom: "5px",
											paddingTop: "5px",
										}}
									>
										<strong>Attachment</strong>
									</div>
									<div
										className="form-group d-block ml-3"
										style={height_style[1]}
									>
										<div
											className="float-left fs-body1 pr-4"
											style={width_style}
										>
											<img
												style={{
													widht: "23px",
													height: "23px",
												}}
												src={images("./share-events-image.svg")}
												alt="facebook"
											></img>
											<h5 className="fs-body1 feature-name pr-1">
												&nbsp; Post attachment
											</h5>
											<CustomSwitch
												checked={elements[activeElement].attachment}
												selectable={elements[activeElement].image != null}
												disabled={
													elements[activeElement].video_attachment_facebook
												}
												className="event-filter__stations-switch"
												onChange={(checked) => {
													changeHandler("attachment", checked);
													if (checked) {
														changeHandler("video_attachment_facebook", false);
													}
												}}
											/>
										</div>
									</div>
									<div
										className="form-group d-block ml-3"
										style={height_style[1]}
									>
										<div
											className="float-left fs-body1 pr-4"
											style={width_style}
										>
											<img
												style={{
													widht: "23px",
													height: "23px",
												}}
												src={images("./share-events-video.svg")}
												alt="facebook"
											></img>
											<h5 className="fs-body1 feature-name pr-1">
												&nbsp; Post as video attachment
											</h5>
											<CustomSwitch
												checked={
													elements[activeElement].video_attachment_facebook
												}
												selectable={elements[activeElement].video != null}
												disabled={elements[activeElement].attachment}
												className="event-filter__stations-switch"
												onChange={(checked) => {
													changeHandler("video_attachment_facebook", checked);
													if (checked) {
														changeHandler("attachment", false);
													}
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							<div style={{ padding: "5px" }}></div>

							<div className="section">
								<div
									className="d-flex flex-column bg-white mt-3 pt-3 pb-3 pl-3 pr-3"
									style={{
										border: "2px solid #dadee2",
										borderRadius: "10px",
										height: "480px",
									}}
								>
									<div
										style={{
											paddingLeft: "15px",
											paddingBottom: "10px",
										}}
									>
										<img
											style={{
												widht: "26px",
												height: "26px",
											}}
											src={images("./share-events-instagram.png")}
											alt="instagram"
										></img>
										<strong className="ml-2">Instagram</strong>
									</div>
									<div
										style={{
											paddingLeft: "15px",
											paddingBottom: "5px",
										}}
									>
										<strong>Post</strong>
									</div>
									<div
										className="form-group d-block ml-3"
										style={height_style[1]}
									>
										<div
											className="float-left fs-body1 pr-4"
											style={width_style}
										>
											<img
												style={{
													widht: "23px",
													height: "23px",
												}}
												src={images("./share-events-image.svg")}
												alt="facebook"
											></img>
											<h5 className="fs-body1 feature-name pr-1">
												&nbsp; Post as image
											</h5>
											<CustomSwitch
												checked={elements[activeElement].instagram_image_post}
												className="event-filter__stations-switch"
												selectable={!elements[activeElement].video}
												disabled={elements[activeElement].instagram_video_post}
												onChange={(checked) => {
													changeHandler("instagram_image_post", checked);
													if (checked) {
														changeHandler("instagram_video_post", false);
													}
												}}
											/>
										</div>
									</div>
									<>
										<div
											className="form-group d-block ml-3"
											style={height_style[1]}
										>
											<div
												className="float-left fs-body1 pr-4"
												style={width_style}
											>
												<img
													style={{
														widht: "23px",
														height: "23px",
													}}
													src={images("./share-events-video.svg")}
													alt="facebook"
												></img>
												<h5 className="fs-body1 feature-name pr-1">
													&nbsp; Post as video
												</h5>
												<CustomSwitch
													checked={elements[activeElement].instagram_video_post}
													className="event-filter__stations-switch"
													selectable={
														!elements[activeElement].video &&
														!elements[activeElement].image
													}
													disabled={
														elements[activeElement].instagram_image_post
													}
													onChange={(checked) => {
														changeHandler("instagram_video_post", checked);
														if (checked) {
															changeHandler("instagram_image_post", false);
														}
													}}
												/>
											</div>
										</div>
										<div
											style={{
												paddingLeft: "15px",
												paddingBottom: "5px",
												paddingTop: "5px",
											}}
										>
											<strong>Story</strong>
										</div>
										<div
											className="form-group d-block ml-3"
											style={height_style[1]}
										>
											<div
												className="float-left fs-body1 pr-4"
												style={width_style}
											>
												<img
													style={{
														widht: "23px",
														height: "23px",
													}}
													src={images("./share-events-image.svg")}
													alt="facebook"
												></img>
												<h5 className="fs-body1 feature-name pr-1">
													&nbsp; Post as story image
												</h5>

												<CustomSwitch
													checked={
														elements[activeElement].image_story_instagram
													}
													className="event-filter__stations-switch"
													disabled={
														elements[activeElement].video_story_instagram
													}
													onChange={(checked) => {
														changeHandler("image_story_instagram", checked);
													}}
												/>
											</div>
										</div>
										<div
											className="form-group d-block ml-3"
											style={height_style[1]}
										>
											<div
												className="float-left fs-body1 pr-4"
												style={width_style}
											>
												<img
													style={{
														widht: "23px",
														height: "23px",
													}}
													src={images("./share-events-video.svg")}
													alt="facebook"
												></img>
												<h5 className="fs-body1 feature-name pr-1">
													&nbsp; Post as story video
												</h5>
												<CustomSwitch
													checked={
														elements[activeElement].video_story_instagram
													}
													selectable={!elements[activeElement].video}
													className="event-filter__stations-switch"
													disabled={
														elements[activeElement].image_story_instagram
													}
													onChange={(checked) => {
														changeHandler("video_story_instagram", checked);
													}}
												/>
											</div>
										</div>
										<div
											style={{
												paddingLeft: "15px",
												paddingBottom: "5px",
												paddingTop: "5px",
											}}
										>
											<strong>Attachment</strong>
										</div>
										<div
											className="form-group d-block ml-3"
											style={height_style[1]}
										>
											<div
												className="float-left fs-body1 pr-4"
												style={width_style}
											>
												<img
													style={{
														widht: "23px",
														height: "23px",
													}}
													src={images("./share-events-image.svg")}
													alt="facebook"
												></img>
												<h5 className="fs-body1 feature-name pr-1">
													&nbsp; Post attachment
												</h5>
												<CustomSwitch
													checked={elements[activeElement].instagram_attachment}
													className="event-filter__stations-switch"
													selectable={elements[activeElement].image != null}
													disabled={
														elements[activeElement].video_attachment_instagram
													}
													onChange={(checked) => {
														changeHandler("instagram_attachment", checked);
														if (checked) {
															changeHandler(
																"video_attachment_instagram",
																false
															);
														}
													}}
												/>
											</div>
										</div>
										<div
											className="form-group d-block ml-3"
											style={height_style[1]}
										>
											<div
												className="float-left fs-body1 pr-4"
												style={width_style}
											>
												<img
													style={{
														widht: "23px",
														height: "23px",
													}}
													src={images("./share-events-video.svg")}
													alt="facebook"
												></img>
												<h5 className="fs-body1 feature-name pr-1">
													&nbsp; Post as video
													attachment
												</h5>
												<CustomSwitch
													checked={
														elements[activeElement].video_attachment_instagram
													}
													className="event-filter__stations-switch"
													selectable={elements[activeElement].video != null}
													disabled={
														elements[activeElement].instagram_attachment
													}
													onChange={(checked) => {
														changeHandler(
															"video_attachment_instagram",
															checked
														);
														if (checked) {
															changeHandler("instagram_attachment", false);
														}
													}}
												/>
											</div>
										</div>
										<div
											className="form-group d-block ml-3"
											style={height_style[1]}
										>
											<div
												className="float-left fs-body1 pr-4"
												style={width_style}
											>
												<img
													style={{
														widht: "23px",
														height: "23px",
													}}
													src={images("./share-events-image.svg")}
													alt="facebook"
												></img>
												<h5 className="fs-body1 feature-name pr-1">
													&nbsp; Post as story
													attachment
												</h5>
												<CustomSwitch
													checked={
														elements[activeElement].attachment_story_instagram
													}
													className="event-filter__stations-switch"
													selectable={elements[activeElement].image != null}
													disabled={
														elements[activeElement]
															.video_attachment_story_instagram
													}
													onChange={(checked) => {
														changeHandler(
															"attachment_story_instagram",
															checked
														);
														if (checked) {
															changeHandler(
																"video_attachment_story_instagram",
																false
															);
														}
													}}
												/>
											</div>
										</div>
										<div
											className="form-group d-block ml-3"
											style={height_style[1]}
										>
											<div
												className="float-left fs-body1 pr-4"
												style={width_style}
											>
												<img
													style={{
														widht: "23px",
														height: "23px",
													}}
													src={images("./share-events-video.svg")}
													alt="facebook"
												></img>
												<h5 className="fs-body1 feature-name pr-1">
													&nbsp; Post as story video
													attachment
												</h5>
												<CustomSwitch
													checked={
														elements[activeElement]
															.video_attachment_story_instagram
													}
													className="event-filter__stations-switch"
													selectable={elements[activeElement].video != null}
													disabled={
														elements[activeElement].attachment_story_instagram
													}
													onChange={(checked) => {
														changeHandler(
															"video_attachment_story_instagram",
															checked
														);
														if (checked) {
															changeHandler(
																"attachment_story_instagram",
																false
															);
														}
													}}
												/>
											</div>
										</div>
									</>
								</div>
							</div>
						</div>

						{elements[activeElement].video_post ||
						elements[activeElement].instagram_video_post ||
						elements[activeElement].video_story_instagram ? (
							<div className="form-group d-block ml-3">
								<div className="float-left fs-body1 pr-4 pt-3">
									<h5 className="fs-body1 feature-name pr-1">Short Video:</h5>
									<CustomSwitch
										checked={elements[activeElement].is_short_video}
										className="event-filter__stations-switch"
										disabled={
											elements[activeElement].attachment_story_instagram
										}
										onChange={(checked) => {
											changeHandler("is_short_video", checked);
										}}
									/>
								</div>
							</div>
						) : null}

						<div className="form-group d-block ml-3">
							<div className="float-left fs-body1 pr-4 pt-3">
								<h5 className="fs-body1 feature-name pr-1">Additional days:</h5>
								<input
									type="number"
									min="0"
									max="6"
									style={{ width: "50px" }}
									value={elements[activeElement].additional_game_days}
									onChange={(event) => {
										changeHandler("additional_game_days", event.target.value);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="rightSide__bottomBar">
					<Button
						type="submit"
						className="button--success no-border-button"
						disabled={!validatePost()}
						onClick={() => {
							if (activeElement == "Draft") createElement();
							else updateElement();
						}}
					>
						{activeElement == "Draft" ? "Create" : "Update"}&nbsp;
						{tab === "posts" ? "Post" : "Posting Rule"}
					</Button>
					<Button
						className="button--warning no-border-button ml-3"
						onClick={() => {
							deleteElement();
						}}
					>
						Delete&nbsp;{tab === "posts" ? "Post" : "Posting Rule"}
					</Button>
				</div>
			</CustomLoader>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		showNotice: (message, success, options) =>
			dispatch(actionCreators.showNotice(message, success, options)),
		hideNotice: (params) => dispatch(actionCreators.hideNotice(params)),
	};
};

export default connect(null, mapDispatchToProps)(ElementDetails);
