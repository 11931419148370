import React from "react";
import { Dropdown } from "react-bootstrap";
import { Button } from "react-bootstrap-buttons";

const dialog = (props) => {
	const displayText = props.tabIndex
		? props.selEvents.length === 1
			? "match selected"
			: "matches selected"
		: props.selEvents.length === 1
		? "event selected"
		: "events selected";

	const pluralize = (text) => {
		return text + (props.selEvents.length > 1 ? "s" : "");
	};

	const eventbar = props.tabIndex ? (
		<div className=" bg-white event-dialog">
			<div className=" d-flex m-auto p-3  event-dialog__bar col-11 align-items-center text--gray11 fs-body2 ">
				<div className="ml-2 mt-2">
					<span className="text--white bg--grad2 fs-body2 event-count">
						{props.selEvents.length}
					</span>
					{displayText}
				</div>
				<Button
					className="button--success ml-auto mr-4 p-1 fs-btn2 "
					onClick={props.onAddEvents}
				>
					{pluralize("Add Event")}
				</Button>
			</div>
		</div>
	) : (
		<div className=" bg-white event-dialog">
			<div className=" d-flex m-auto p-3  event-dialog__bar col-11 align-items-center text--gray11">
				<Button
					className="button--warning ml-auto mr-4 p-1 fs-btn2 "
					onClick={() => props.onDeleteEvents()}
				>
					{pluralize("Delete Event")}
				</Button>
				{/* <Button
          className="button--success mr-4 p-1 fs-btn2 "
          onClick={props.onPrintPoster}
        >
          Print Poster
        </Button>
        <Button
          className="button--success mr-4 p-1 fs-btn2 "
          onClick={props.onPrintEvents}
        >
          Print Excel
        </Button> */}

				<Dropdown drop="up">
					<Dropdown.Toggle
						variant="success"
						id="dropdown-basic"
						className="button--success mr-4 p-1 fs-btn2 "
					>
						Export
					</Dropdown.Toggle>

					<Dropdown.Menu className="dropd-up-inset">
						<Dropdown.Item onClick={props.onDownloadVideo}>
							Download Video
						</Dropdown.Item>
						<Dropdown.Item onClick={props.onDownloadVideoShort}>
							Download Video (Short version)
						</Dropdown.Item>
						<Dropdown.Item onClick={props.onPrintPoster}>
							Print Poster
						</Dropdown.Item>
						<Dropdown.Item onClick={props.onPrintEvents}>
							Print Excel
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>

				<Button
					className="button--success p-1 fs-btn2 "
					onClick={props.onShareEvents}
				>
					{pluralize("Share Event")}
				</Button>
			</div>
		</div>
	);
	return eventbar;
};

export default dialog;
